@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import "daisyui/navbar";

@import "components/section";
@import "components/typography";
@import "components/button";
@import "components/game-carousel";
@import "components/stat";
@import "components/partner";
@import "components/animate";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

