.payu-unite-1
{
    border-radius: 55% 55% 55% 55% / 35% 35% 35% 35%;
}

.payu-disunite-1
{
    border-radius: 58% 60% 50% 65% / 60% 34% 80% 50%;
}

.payu-disunite-2
{
    border-radius: 80% 35% 50% 65% / 45% 65% 40% 65%;
}


.payu-btn
{
    @apply text-white px-5 py-2 text-lg relative inline-block uppercase no-underline overflow-hidden rounded-lg bg-theme-800 border-2 border-theme border-opacity-80 transition-all font-medium tracking-wider text-center;

    box-shadow: 0 0 5px #005EC9,
        0 0 25px #005EC9;
}

.payu-btn.gold
{
    @apply bg-gold-800 border-2 border-gold;

    box-shadow: 0 0 5px #FF9E00,
        0 0 25px #FF9E00;
}

.payu-btn:hover
{
    @apply bg-theme-600;
    box-shadow: 0 0 5px #0279FF,
        0 0 25px #0279FF,
        0 0 50px #0279FF,
        0 0 100px #0279FF;
}

.payu-btn.gold:hover
{
    @apply bg-gold-600 text-gold-900;
    box-shadow: 0 0 5px #FFB338,
        0 0 25px #FFB338,
        0 0 50px #FFB338,
        0 0 100px #FFB338;
}

.payu-btn span
{
    position: absolute;
    display: block;
}

.payu-btn span:nth-child(1)
{
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #54A4FF);
    animation: btn-anim1 1s linear infinite;
}

.payu-btn span:nth-child(2)
{
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #54A4FF);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
}

.payu-btn span:nth-child(3)
{
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #54A4FF);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
}

.payu-btn span:nth-child(4)
{
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #54A4FF);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
}



.payu-btn.gold span:nth-child(1)
{
    background: linear-gradient(90deg, transparent, #FFD28A);
}

.payu-btn.gold span:nth-child(2)
{
    background: linear-gradient(180deg, transparent, #FFD28A);
}

.payu-btn.gold span:nth-child(3)
{
    background: linear-gradient(270deg, transparent, #FFD28A);
}

.payu-btn.gold span:nth-child(4)
{
    background: linear-gradient(360deg, transparent, #FFD28A);
}

@keyframes btn-anim1
{
    0%
    {
        left: -100%;
    }

    50%,
    100%
    {
        left: 100%;
    }
}

@keyframes btn-anim2
{
    0%
    {
        top: -100%;
    }

    50%,
    100%
    {
        top: 100%;
    }
}

@keyframes btn-anim3
{
    0%
    {
        right: -100%;
    }

    50%,
    100%
    {
        right: 100%;
    }
}

@keyframes btn-anim4
{
    0%
    {
        bottom: -100%;
    }

    50%,
    100%
    {
        bottom: 100%;
    }
}