.stat
{
    @apply bg-gradient-to-tr from-theme-500 to-theme-700 text-center text-white w-full max-w-[200px] py-5 inline-block m-6 border-4 border-white payu-unite-1;

    box-shadow: 0 0 5px #FF9E00,
        0 0 25px #FF9E00;
}

.stat .stat-title
{
    @apply opacity-100;
}

.stat .stat-value
{
    @apply my-2;
}

.stat .stat-desc
{
    @apply text-base text-white;
}